import getCustomer from '../queries/getCustomer.graphql';
import getPunchoutCustomer from '../queries/getPunchoutCustomer.graphql';
import getCompany from '../queries/getCompany.graphql';
import getStoreConfig from '../queries/getStoreConfig.gql.js';
import logoutMutation from '../queries/mutations/logout.graphql';

export default async (context, inject) => {
    const auth = {};

    async function getUser() {
        try {
            const token = context.$storage.getCookie('apollo-token');
            if (!token) {
                throw new Error('No Token');
            }
            const isPunchoutCustomer = Boolean(context.$storage.getCookie('punchout_login'));
            const client = context.app.apolloProvider.defaultClient;
            const customerData = await client.query({ query: isPunchoutCustomer ? getPunchoutCustomer : getCustomer, fetchPolicy: 'network-only' });
            const companyData = await client.query({ query: getCompany, fetchPolicy: 'network-only' });
            const {
                data: { storeConfig },
            } = await client.query({ query: getStoreConfig, fetchPolicy: 'network-only' });

            const user = { ...customerData.data.customer };
            user.company = { ...companyData.data.company };

            const isSuperAdmin = user.email === user.company.company_admin.email;

            user.isSuperAdmin = isSuperAdmin;

            if (!isSuperAdmin && user.role) {
                const flattenPermissions = ({ children }) => children.flatMap(({ children = [], ...rest }) => [rest, ...flattenPermissions({ children })]);
                const userPermissions = user.role.permissions;
                const userPermissionsList = userPermissions
                    .map((permissionsRootLevel) => flattenPermissions(permissionsRootLevel).map((permission) => permission.id))
                    .flat(1);
                const subAdminPermissions = [
                    'Amasty_CompanyAccount::users_add',
                    'Amasty_CompanyAccount::users_edit',
                    'Amasty_CompanyAccount::users_delete',
                    'Amasty_CompanyAccount::addr_edit',
                    'Amasty_CompanyAccount::sl_edit',
                    'Amasty_CompanyAccount::orders_all_view',
                ];
                const isSubAdmin = subAdminPermissions.every((subAdminPermission) => userPermissionsList.includes(subAdminPermission));
                user.isAdmin = isSubAdmin;
            } else {
                user.isAdmin = isSuperAdmin;
            }

            await context.store.commit('auth/setLogged', true);
            await context.store.commit('auth/setUser', user);
            await context.store.commit('auth/setStoreConfig', storeConfig);
            const adminLogin = Boolean(context.$storage.getCookie('admin_login'));
            await context.store.commit('auth/setAdminLogin', adminLogin);
            const punchoutLogin = Boolean(context.$storage.getCookie('punchout_login'));
            await context.store.commit('auth/setPunchoutLogin', punchoutLogin);

            try {
                const customLogo = await context.$strapi.getEntries({ contentType: 'custom-logos', populate: 'image' });
                if (
                    customLogo.data &&
                    customLogo.data.length &&
                    customLogo.data[0].attributes.image &&
                    customLogo.data[0].attributes.image.data.attributes.url
                ) {
                    await context.store.commit('auth/setCustomLogoUrl', customLogo.data[0].attributes.image.data.attributes.url);
                } else {
                    await context.store.commit('auth/setCustomLogoUrl', null);
                }
            } catch (e) {
                await context.store.commit('auth/setCustomLogoUrl', null);
            }

            try {
                const freeDeliveryLimits = await context.$strapi.getEntries({ contentType: 'free-delivery-limits' });
                if (freeDeliveryLimits.data && freeDeliveryLimits.data.length && Number.isFinite(freeDeliveryLimits.data[0].attributes.limit)) {
                    await context.store.commit('auth/setFreeShippingLimit', freeDeliveryLimits.data[0].attributes.limit);
                } else {
                    await context.store.commit('auth/setFreeShippingLimit', storeConfig.free_shipping_subtotal);
                }
            } catch (e) {
                await context.store.commit('auth/setFreeShippingLimit', storeConfig.free_shipping_subtotal);
            }

            if (!punchoutLogin) {
                await context.store.dispatch('cart/setCartId', customerData.data.customerCart.id);
            }
            await context.store.dispatch('cart/getCart');
        } catch (e) {
            const client = context.app.apolloProvider.defaultClient;
            const {
                data: { storeConfig },
            } = await client.query({ query: getStoreConfig, fetchPolicy: 'network-only' });
            await context.store.commit('auth/setLogged', false);
            await context.store.commit('auth/setUser', {});
            await context.store.commit('auth/setStoreConfig', storeConfig);
            await context.store.commit('auth/setCustomLogoUrl', null);

            try {
                const freeDeliveryLimits = await context.$strapi.getEntries({ contentType: 'free-delivery-limits' });
                if (freeDeliveryLimits.data && freeDeliveryLimits.data.length && Number.isFinite(freeDeliveryLimits.data[0].attributes.limit)) {
                    await context.store.commit('auth/setFreeShippingLimit', freeDeliveryLimits.data[0].attributes.limit);
                } else {
                    await context.store.commit('auth/setFreeShippingLimit', storeConfig.free_shipping_subtotal);
                }
            } catch (e) {
                await context.store.commit('auth/setFreeShippingLimit', storeConfig.free_shipping_subtotal);
            }

            await context.app.$apolloHelpers.onLogout();
            context.$storage.removeCookie('apollo-token');
            context.$storage.removeCookie('admin_login');
            context.$storage.removeCookie('medituote-catalog');
            context.$storage.removeCookie('punchout_login');
            context.$storage.setCookie('cart_qty', 0);
            await context.store.dispatch('cart/getCart');
        }
    }

    auth.getUser = getUser;

    async function unsetUser() {
        const client = context.app.apolloProvider.defaultClient;
        try {
            await client
                .mutate({
                    mutation: logoutMutation,
                })
                .then(({ data }) => data && data.revokeCustomerToken);
            await context.app.$apolloHelpers.onLogout();
            await client.cache.reset();
            await client.resetStore();
        } catch (e) {}

        const {
            data: { storeConfig },
        } = await client.query({ query: getStoreConfig, fetchPolicy: 'network-only' });
        await context.store.commit('auth/setLogged', false);
        await context.store.commit('auth/setUser', {});
        await context.store.commit('auth/setStoreConfig', storeConfig);
        await context.store.commit('cart/setCart', {});
        context.$storage.setCookie('cart_qty', 0);
        context.$storage.removeCookie('cart_id');
        context.$storage.removeCookie('apollo-token');
        context.$storage.removeCookie('admin_login');
        context.$storage.removeCookie('medituote-catalog');
        context.$storage.removeCookie('punchout_login');

        await context.store.dispatch('wishlist/emptyWishlist');

        // Sleep before logging in again to ensure that Apollo cleared store
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    auth.unsetUser = unsetUser;

    async function login({ token, loginType, cartId }) {
        const client = context.app.apolloProvider.defaultClient;
        await context.app.$apolloHelpers.onLogin(token);
        context.$storage.setCookie('apollo-token', token);
        await client.cache.reset();
        await client.resetStore();

        if (loginType === 'frontend') {
            await context.store.commit('auth/setAdminLogin', false);
            context.$storage.removeCookie('admin_login');
            context.$storage.removeCookie('medituote-catalog');
            context.$storage.removeCookie('punchout_login');
        }

        if (loginType === 'magento') {
            await context.store.commit('auth/setAdminLogin', true);
            context.$storage.setCookie('admin_login', true);
        }

        if (loginType === 'punchout') {
            await context.store.commit('auth/setPunchoutLogin', true);
            context.$storage.setCookie('cart_id', cartId, { maxAge: 2592000 });
            context.$storage.setCookie('punchout_login', true);
        }
    }

    auth.login = login;

    inject('auth', auth);
    await getUser();
};
