import { render, staticRenderFns } from "./minimal.vue?vue&type=template&id=17692e80&"
import script from "./minimal.vue?vue&type=script&lang=js&"
export * from "./minimal.vue?vue&type=script&lang=js&"
import style0 from "./minimal.vue?vue&type=style&index=0&id=17692e80&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CookieConsent: require('/~/src/components/CookieConsent.vue').default,OpusIcon: require('/~/src/modules/opus/components/Icon.vue').default,Logo: require('/~/src/modules/opus-ecom/components/Logo.vue').default})
