export const OpusAccordion = () => import('../../src/modules/opus/components/Accordion.vue' /* webpackChunkName: "components/opus-accordion" */).then(c => wrapFunctional(c.default || c))
export const OpusAccordionItem = () => import('../../src/modules/opus/components/AccordionItem.vue' /* webpackChunkName: "components/opus-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const OpusBadge = () => import('../../src/modules/opus/components/Badge.vue' /* webpackChunkName: "components/opus-badge" */).then(c => wrapFunctional(c.default || c))
export const OpusBlockquote = () => import('../../src/modules/opus/components/Blockquote.vue' /* webpackChunkName: "components/opus-blockquote" */).then(c => wrapFunctional(c.default || c))
export const OpusCard = () => import('../../src/modules/opus/components/Card.vue' /* webpackChunkName: "components/opus-card" */).then(c => wrapFunctional(c.default || c))
export const OpusDialog = () => import('../../src/modules/opus/components/Dialog.vue' /* webpackChunkName: "components/opus-dialog" */).then(c => wrapFunctional(c.default || c))
export const OpusIcon = () => import('../../src/modules/opus/components/Icon.vue' /* webpackChunkName: "components/opus-icon" */).then(c => wrapFunctional(c.default || c))
export const OpusLink = () => import('../../src/modules/opus/components/Link.vue' /* webpackChunkName: "components/opus-link" */).then(c => wrapFunctional(c.default || c))
export const OpusModal = () => import('../../src/modules/opus/components/Modal.vue' /* webpackChunkName: "components/opus-modal" */).then(c => wrapFunctional(c.default || c))
export const OpusQuantitySelector = () => import('../../src/modules/opus/components/QuantitySelector.vue' /* webpackChunkName: "components/opus-quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const OpusSearchSelect = () => import('../../src/modules/opus/components/SearchSelect.vue' /* webpackChunkName: "components/opus-search-select" */).then(c => wrapFunctional(c.default || c))
export const OpusSelect = () => import('../../src/modules/opus/components/Select.vue' /* webpackChunkName: "components/opus-select" */).then(c => wrapFunctional(c.default || c))
export const OpusTitle = () => import('../../src/modules/opus/components/Title.vue' /* webpackChunkName: "components/opus-title" */).then(c => wrapFunctional(c.default || c))
export const OpusButton = () => import('../../src/modules/opus/components/Form/Button.vue' /* webpackChunkName: "components/opus-button" */).then(c => wrapFunctional(c.default || c))
export const OpusCheckbox = () => import('../../src/modules/opus/components/Form/Checkbox.vue' /* webpackChunkName: "components/opus-checkbox" */).then(c => wrapFunctional(c.default || c))
export const OpusInput = () => import('../../src/modules/opus/components/Form/Input.vue' /* webpackChunkName: "components/opus-input" */).then(c => wrapFunctional(c.default || c))
export const OpusLabel = () => import('../../src/modules/opus/components/Form/Label.vue' /* webpackChunkName: "components/opus-label" */).then(c => wrapFunctional(c.default || c))
export const OpusRenderInput = () => import('../../src/modules/opus/components/Form/RenderInput.vue' /* webpackChunkName: "components/opus-render-input" */).then(c => wrapFunctional(c.default || c))
export const AccountNavigation = () => import('../../src/modules/opus-ecom/components/AccountNavigation.vue' /* webpackChunkName: "components/account-navigation" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../src/modules/opus-ecom/components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../src/modules/opus-ecom/components/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CategoryTreeMenu = () => import('../../src/modules/opus-ecom/components/CategoryTreeMenu.vue' /* webpackChunkName: "components/category-tree-menu" */).then(c => wrapFunctional(c.default || c))
export const ColorMode = () => import('../../src/modules/opus-ecom/components/ColorMode.vue' /* webpackChunkName: "components/color-mode" */).then(c => wrapFunctional(c.default || c))
export const CookieConsent = () => import('../../src/components/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/modules/opus-ecom/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HanselExternalNotification = () => import('../../src/modules/opus-ecom/components/HanselExternalNotification.vue' /* webpackChunkName: "components/hansel-external-notification" */).then(c => wrapFunctional(c.default || c))
export const HanselStoreSwitcher = () => import('../../src/modules/opus-ecom/components/HanselStoreSwitcher.vue' /* webpackChunkName: "components/hansel-store-switcher" */).then(c => wrapFunctional(c.default || c))
export const HanselStoreSwitcherDialog = () => import('../../src/modules/opus-ecom/components/HanselStoreSwitcherDialog.vue' /* webpackChunkName: "components/hansel-store-switcher-dialog" */).then(c => wrapFunctional(c.default || c))
export const LayeredFilters = () => import('../../src/modules/opus-ecom/components/LayeredFilters.vue' /* webpackChunkName: "components/layered-filters" */).then(c => wrapFunctional(c.default || c))
export const LayeredNavigation = () => import('../../src/modules/opus-ecom/components/LayeredNavigation.vue' /* webpackChunkName: "components/layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const LayeredPagination = () => import('../../src/modules/opus-ecom/components/LayeredPagination.vue' /* webpackChunkName: "components/layered-pagination" */).then(c => wrapFunctional(c.default || c))
export const LayeredSort = () => import('../../src/modules/opus-ecom/components/LayeredSort.vue' /* webpackChunkName: "components/layered-sort" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../src/modules/opus-ecom/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoSmallMobile = () => import('../../src/modules/opus-ecom/components/LogoSmallMobile.vue' /* webpackChunkName: "components/logo-small-mobile" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../src/modules/opus-ecom/components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NewProducts = () => import('../../src/modules/opus-ecom/components/NewProducts.vue' /* webpackChunkName: "components/new-products" */).then(c => wrapFunctional(c.default || c))
export const OriginalLogo = () => import('../../src/modules/opus-ecom/components/OriginalLogo.vue' /* webpackChunkName: "components/original-logo" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../src/modules/opus-ecom/components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductLabel = () => import('../../src/modules/opus-ecom/components/ProductLabel.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../src/components/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductPageSkeleton = () => import('../../src/modules/opus-ecom/components/ProductPageSkeleton.vue' /* webpackChunkName: "components/product-page-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../src/modules/opus-ecom/components/ProductReview.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewModal = () => import('../../src/modules/opus-ecom/components/ProductReviewModal.vue' /* webpackChunkName: "components/product-review-modal" */).then(c => wrapFunctional(c.default || c))
export const Quicksearch = () => import('../../src/modules/opus-ecom/components/Quicksearch.vue' /* webpackChunkName: "components/quicksearch" */).then(c => wrapFunctional(c.default || c))
export const ReadMore = () => import('../../src/modules/opus-ecom/components/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c))
export const RecentlyViewed = () => import('../../src/modules/opus-ecom/components/RecentlyViewed.vue' /* webpackChunkName: "components/recently-viewed" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../src/modules/opus-ecom/components/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const SubscribeOutOfStock = () => import('../../src/modules/opus-ecom/components/SubscribeOutOfStock.vue' /* webpackChunkName: "components/subscribe-out-of-stock" */).then(c => wrapFunctional(c.default || c))
export const AccountBack = () => import('../../src/modules/opus-ecom/components/Account/AccountBack.vue' /* webpackChunkName: "components/account-back" */).then(c => wrapFunctional(c.default || c))
export const AccountCustomerData = () => import('../../src/modules/opus-ecom/components/Account/AccountCustomerData.vue' /* webpackChunkName: "components/account-customer-data" */).then(c => wrapFunctional(c.default || c))
export const AccountLinks = () => import('../../src/modules/opus-ecom/components/Account/AccountLinks.vue' /* webpackChunkName: "components/account-links" */).then(c => wrapFunctional(c.default || c))
export const AccountNagivation = () => import('../../src/modules/opus-ecom/components/Account/AccountNagivation.vue' /* webpackChunkName: "components/account-nagivation" */).then(c => wrapFunctional(c.default || c))
export const CustomerReviews = () => import('../../src/modules/opus-ecom/components/Account/CustomerReviews.vue' /* webpackChunkName: "components/customer-reviews" */).then(c => wrapFunctional(c.default || c))
export const DetailedOrderInfo = () => import('../../src/modules/opus-ecom/components/Account/DetailedOrderInfo.vue' /* webpackChunkName: "components/detailed-order-info" */).then(c => wrapFunctional(c.default || c))
export const OrderRenderer = () => import('../../src/modules/opus-ecom/components/Account/OrderRenderer.vue' /* webpackChunkName: "components/order-renderer" */).then(c => wrapFunctional(c.default || c))
export const OrderRendererItem = () => import('../../src/modules/opus-ecom/components/Account/OrderRendererItem.vue' /* webpackChunkName: "components/order-renderer-item" */).then(c => wrapFunctional(c.default || c))
export const OrderStatusBar = () => import('../../src/modules/opus-ecom/components/Account/OrderStatusBar.vue' /* webpackChunkName: "components/order-status-bar" */).then(c => wrapFunctional(c.default || c))
export const Reorder = () => import('../../src/modules/opus-ecom/components/Account/Reorder.vue' /* webpackChunkName: "components/reorder" */).then(c => wrapFunctional(c.default || c))
export const WishlistItem = () => import('../../src/modules/opus-ecom/components/Account/WishlistItem.vue' /* webpackChunkName: "components/wishlist-item" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../src/modules/opus-ecom/components/Cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const MinicartItem = () => import('../../src/modules/opus-ecom/components/Cart/MinicartItem.vue' /* webpackChunkName: "components/minicart-item" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../src/modules/opus-ecom/components/Catalog/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CrossProducts = () => import('../../src/modules/opus-ecom/components/Catalog/CrossProducts.vue' /* webpackChunkName: "components/cross-products" */).then(c => wrapFunctional(c.default || c))
export const ProductCardAddToCart = () => import('../../src/modules/opus-ecom/components/Catalog/ProductCardAddToCart.vue' /* webpackChunkName: "components/product-card-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductImageGallery = () => import('../../src/modules/opus-ecom/components/Catalog/ProductImageGallery.vue' /* webpackChunkName: "components/product-image-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewCard = () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewCard.vue' /* webpackChunkName: "components/product-review-card" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewSection = () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewSection.vue' /* webpackChunkName: "components/product-review-section" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewStars = () => import('../../src/modules/opus-ecom/components/Catalog/ProductReviewStars.vue' /* webpackChunkName: "components/product-review-stars" */).then(c => wrapFunctional(c.default || c))
export const WishlistButton = () => import('../../src/modules/opus-ecom/components/Catalog/WishlistButton.vue' /* webpackChunkName: "components/wishlist-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddress = () => import('../../src/modules/opus-ecom/components/Checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethods = () => import('../../src/modules/opus-ecom/components/Checkout/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c))
export const ShippingMethods = () => import('../../src/modules/opus-ecom/components/Checkout/ShippingMethods.vue' /* webpackChunkName: "components/shipping-methods" */).then(c => wrapFunctional(c.default || c))
export const Totals = () => import('../../src/modules/opus-ecom/components/Checkout/Totals.vue' /* webpackChunkName: "components/totals" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../src/modules/opus-ecom/components/Cms/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const Cta = () => import('../../src/modules/opus-ecom/components/Cms/Cta.vue' /* webpackChunkName: "components/cta" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../src/modules/opus-ecom/components/Cms/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../src/components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const BannerCard = () => import('../../src/components/BannerCard.vue' /* webpackChunkName: "components/banner-card" */).then(c => wrapFunctional(c.default || c))
export const BillingShippingAddress = () => import('../../src/components/BillingShippingAddress.vue' /* webpackChunkName: "components/billing-shipping-address" */).then(c => wrapFunctional(c.default || c))
export const Blog = () => import('../../src/components/Blog.vue' /* webpackChunkName: "components/blog" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../src/components/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const CallToActionItem = () => import('../../src/components/CallToActionItem.vue' /* webpackChunkName: "components/call-to-action-item" */).then(c => wrapFunctional(c.default || c))
export const CartTotals = () => import('../../src/components/CartTotals.vue' /* webpackChunkName: "components/cart-totals" */).then(c => wrapFunctional(c.default || c))
export const CategoryBanner = () => import('../../src/components/CategoryBanner.vue' /* webpackChunkName: "components/category-banner" */).then(c => wrapFunctional(c.default || c))
export const CategoryPage = () => import('../../src/components/CategoryPage.vue' /* webpackChunkName: "components/category-page" */).then(c => wrapFunctional(c.default || c))
export const CategoryPromotion = () => import('../../src/components/CategoryPromotion.vue' /* webpackChunkName: "components/category-promotion" */).then(c => wrapFunctional(c.default || c))
export const CategoryTree = () => import('../../src/components/CategoryTree.vue' /* webpackChunkName: "components/category-tree" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBillingAddress = () => import('../../src/components/CheckoutBillingAddress.vue' /* webpackChunkName: "components/checkout-billing-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStep = () => import('../../src/components/CheckoutStep.vue' /* webpackChunkName: "components/checkout-step" */).then(c => wrapFunctional(c.default || c))
export const Coupon = () => import('../../src/components/Coupon.vue' /* webpackChunkName: "components/coupon" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage = () => import('../../src/components/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c))
export const EstimatedShipping = () => import('../../src/components/EstimatedShipping.vue' /* webpackChunkName: "components/estimated-shipping" */).then(c => wrapFunctional(c.default || c))
export const FreeShippingNotification = () => import('../../src/components/FreeShippingNotification.vue' /* webpackChunkName: "components/free-shipping-notification" */).then(c => wrapFunctional(c.default || c))
export const HanselMaterials = () => import('../../src/components/HanselMaterials.vue' /* webpackChunkName: "components/hansel-materials" */).then(c => wrapFunctional(c.default || c))
export const HanselOrderSwitcher = () => import('../../src/components/HanselOrderSwitcher.vue' /* webpackChunkName: "components/hansel-order-switcher" */).then(c => wrapFunctional(c.default || c))
export const HanselProductBadge = () => import('../../src/components/HanselProductBadge.vue' /* webpackChunkName: "components/hansel-product-badge" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../src/components/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HighlightedCategories = () => import('../../src/components/HighlightedCategories.vue' /* webpackChunkName: "components/highlighted-categories" */).then(c => wrapFunctional(c.default || c))
export const HomePage = () => import('../../src/components/HomePage.vue' /* webpackChunkName: "components/home-page" */).then(c => wrapFunctional(c.default || c))
export const Klarna = () => import('../../src/components/Klarna.vue' /* webpackChunkName: "components/klarna" */).then(c => wrapFunctional(c.default || c))
export const KlarnaSuccess = () => import('../../src/components/KlarnaSuccess.vue' /* webpackChunkName: "components/klarna-success" */).then(c => wrapFunctional(c.default || c))
export const ListedLink = () => import('../../src/components/ListedLink.vue' /* webpackChunkName: "components/listed-link" */).then(c => wrapFunctional(c.default || c))
export const LoginAsCustomer = () => import('../../src/components/LoginAsCustomer.vue' /* webpackChunkName: "components/login-as-customer" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../src/components/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../src/components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const PaytrailMethods = () => import('../../src/components/PaytrailMethods.vue' /* webpackChunkName: "components/paytrail-methods" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../src/components/ProductAttributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductBadge = () => import('../../src/components/ProductBadge.vue' /* webpackChunkName: "components/product-badge" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../src/components/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPageLogin = () => import('../../src/components/ProductPageLogin.vue' /* webpackChunkName: "components/product-page-login" */).then(c => wrapFunctional(c.default || c))
export const ProductPolicies = () => import('../../src/components/ProductPolicies.vue' /* webpackChunkName: "components/product-policies" */).then(c => wrapFunctional(c.default || c))
export const ProductPreview = () => import('../../src/components/ProductPreview.vue' /* webpackChunkName: "components/product-preview" */).then(c => wrapFunctional(c.default || c))
export const ProductQuantity = () => import('../../src/components/ProductQuantity.vue' /* webpackChunkName: "components/product-quantity" */).then(c => wrapFunctional(c.default || c))
export const RegisterBenefits = () => import('../../src/components/RegisterBenefits.vue' /* webpackChunkName: "components/register-benefits" */).then(c => wrapFunctional(c.default || c))
export const RegisterCard = () => import('../../src/components/RegisterCard.vue' /* webpackChunkName: "components/register-card" */).then(c => wrapFunctional(c.default || c))
export const RenderAddress = () => import('../../src/components/RenderAddress.vue' /* webpackChunkName: "components/render-address" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../src/components/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const SEOText = () => import('../../src/components/SEOText.vue' /* webpackChunkName: "components/s-e-o-text" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../src/components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Slider2 = () => import('../../src/components/Slider2.vue' /* webpackChunkName: "components/slider2" */).then(c => wrapFunctional(c.default || c))
export const StaticPage = () => import('../../src/components/StaticPage.vue' /* webpackChunkName: "components/static-page" */).then(c => wrapFunctional(c.default || c))
export const StockStatus = () => import('../../src/components/StockStatus.vue' /* webpackChunkName: "components/stock-status" */).then(c => wrapFunctional(c.default || c))
export const TextBanner = () => import('../../src/components/TextBanner.vue' /* webpackChunkName: "components/text-banner" */).then(c => wrapFunctional(c.default || c))
export const TierPrice = () => import('../../src/components/TierPrice.vue' /* webpackChunkName: "components/tier-price" */).then(c => wrapFunctional(c.default || c))
export const WrongStoreLogin = () => import('../../src/components/WrongStoreLogin.vue' /* webpackChunkName: "components/wrong-store-login" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
